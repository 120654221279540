import React  from 'react';
import ViewSubscription from './view-subscription'

export default function ProfileSettings({ params }) {
    // When visiting a route like `image/hello/world`, the value of `param` is `hello/world`.
    // const param = params[`*`]

    const [ url, setUrl ] = React.useState(false)

    React.useEffect(()=>{

        const extractURLAfterKey = ( key ) => {
            const url = window.location.href
            const indexUID = url.indexOf(`/${key}`) + `/${key}`.length + "/".length
            const uid = url.substring(indexUID, url.length).trim()
            return uid
        }

        const url = extractURLAfterKey('profile').trim()

        setUrl( url )


    },[])


    if(!url) return <></>

    
    return <>
        
        <ViewSubscription id={url}/>
        
    </>
}



